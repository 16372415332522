import React from "react"
import { Alert, Button, Col, Form, Row, Spin, Typography } from "antd"
import { Email, Name, Submit, UTMParams } from "./Fields"
import { antdHelpers } from "@avldev/gatsby-active-campaign-forms"
import { formUrl } from "../../../config/forms"

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class OptInModal extends React.Component {
  formRef = React.createRef(null)

  state = {
    formSubmitted: false,
    formSuccess: false,
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  handleSubmit = event => {
    event.preventDefault()

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      const { current: form } = this.formRef
      if (!form) return

      const onSubmitStart = () => {
        this.setState({
          buttonIcon: `loading`,
          name: values.name.split(` `)[0],
        })
      }

      const onSubmitEnd = status => {
        this.setState({ formSuccess: status === 200 })
      }

      antdHelpers.onSubmit(
        form,
        { onSubmitStart, onSubmitEnd },
        process.env.NODE_ENV
      )
    })
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form
    const nameError = isFieldTouched("name") && getFieldError("name")
    const emailError = isFieldTouched("email") && getFieldError("email")

    return (
      <>
        {!this.state.formSuccess ? (
          <Spin spinning={this.state.formSubmitted && !this.state.formSuccess}>
            <form
              action={formUrl}
              onSubmit={this.handleSubmit}
              ref={this.formRef}
              style={{ textAlign: `left`, width: `100%` }}
            >
              {/* <UTMParams decorator={getFieldDecorator} /> */}
              <input type="hidden" name="u" value="10" data-name="u" />
              <input type="hidden" name="f" value="10" data-name="f" />
              <input type="hidden" name="s" data-name="s" />
              <input type="hidden" name="c" value="0" data-name="c" />
              <input type="hidden" name="m" value="0" data-name="m" />
              <input type="hidden" name="act" value="sub" data-name="act" />
              <input type="hidden" name="v" value="2" data-name="v" />
              <input
                type="hidden"
                name="or"
                value="96b29049631168ea8217b65d8614999d"
                data-name="or"
              />
              <Name decorator={getFieldDecorator} validator={nameError} />
              <Email decorator={getFieldDecorator} validator={emailError} />
              {this.props.secondaryButtonText ? (
                <Button size="large" type="ghost">
                  {this.props.secondaryButtonText || "Cancel"}
                </Button>
              ) : (
                <></>
              )}
              <Row align="middle" gutter={16} justify="start" type="flex">
                <Col span={12}>
                  <Form.Item>
                    <Button
                      block
                      onClick={this.props.toggleModal}
                      size="large"
                      type="ghost"
                    >
                      {this.props.cancelText || "Close"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Submit
                    id={this.props.gtmClickId}
                    disabled={hasErrors(getFieldsError())}
                    text={this.props.submitText || "Submit"}
                  />
                </Col>
              </Row>
            </form>
          </Spin>
        ) : (
          <Row align="middle" justify="center" type="flex">
            <Col span={24}>
              <Alert
                description={
                  <Typography.Paragraph>
                    Thanks for signing up! We'll send you our best offers as
                    soon as they become available.
                  </Typography.Paragraph>
                }
                message={`Got it!`}
                onClose={this.props.toggleModal}
                type="success"
                showIcon
              />
            </Col>
            <Col xs={24} md={18} style={{ margin: `24px auto` }}>
              <Button
                block
                onClick={this.props.toggleModal}
                size="large"
                type="ghost"
              >
                Close Window
              </Button>
            </Col>
          </Row>
        )}
      </>
    )
  }
}

export default Form.create({ name: "opt_in_form" })(OptInModal)
